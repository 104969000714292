export default {
	init() {
		// JavaScript to be fired on all pages
		console.log('Team');

		const audioPlayer = document.querySelector('#audioPlayer');
		const src = audioPlayer.getAttribute('data-src');
		const audio = new Audio(
			src || 'https://samplelib.com/lib/preview/mp3/sample-15s.mp3'
		);

		const timeline = audioPlayer.querySelector('.timeline');
		timeline.addEventListener(
			'click',
			(e) => {
				const timelineWidth = window.getComputedStyle(timeline).width;
				const timeToSeek =
					(e.offsetX / parseInt(timelineWidth)) * audio.duration;
				audio.currentTime = timeToSeek;
			},
			false
		);

		audio.addEventListener('ended', (e) => {
			audio.currentTime = 0;
			playBtn.classList.remove('pause');
			playBtn.classList.add('play');
			audio.pause();
		});

		setInterval(() => {
			const progressBar = audioPlayer.querySelector('.progress');
			progressBar.style.width =
				(audio.currentTime / audio.duration) * 100 + '%';
		}, 200);

		const playBtn = audioPlayer.querySelector('.toggle-play');
		playBtn.addEventListener(
			'click',
			() => {
				if (audio.paused) {
					playBtn.classList.remove('play');
					playBtn.classList.add('pause');
					audio.play();
				} else {
					playBtn.classList.remove('pause');
					playBtn.classList.add('play');
					audio.pause();
				}
			},
			false
		);
	},
	finalize() {
		// JavaScript to be fired on all pages, after page specific JS is fired
		console.log('finalize Team');
	},
};
