const DEV = process.env.NODE_ENV === 'development';

import lightGallery from 'lightgallery';
import lgVideo from 'lightgallery/plugins/video';

export default {
	init() {
		// JavaScript to be fired on all pages
		console.log('init common');

		// Remove no-js
		document.documentElement.classList.remove('no-js');
		document.documentElement.classList.add('js');

		// Test touch
		if (!('ontouchstart' in document.documentElement)) {
			document.documentElement.classList.add('no-touch');
		} else {
			document.documentElement.classList.add('touch');
		}

		document.querySelectorAll('.js-toggle-class').forEach((item) => {
			item.addEventListener('click', (event) => {
				let tg = item.getAttribute('data-target');
				let className = item.getAttribute('data-classname');
				if (tg && className) {
					item.classList.toggle('clicked');
					document.querySelectorAll(tg).forEach((target) => {
						target.classList.toggle(className);
					});
				}
			});
		});

		//Vimeo lightbox
		document.querySelectorAll('.js-vimeo-lightbox').forEach((item, i) => {
			item.id = 'gallery-' + i;
			lightGallery(document.getElementById('gallery-' + i), {
				selector: 'this',
				plugins: [lgVideo],
				download: false,
				counter: false,
			});
		});

		const loader = document.getElementById('loader');

		function hideLoader() {
			loader.classList.add('hide');
		}

		function showLoader() {
			loader.classList.add('show');
		}

		if (!DEV) {
			showLoader();
			setTimeout(function () {
				hideLoader();
			}, 2000);

			window.addEventListener('load', function () {
				hideLoader();
			});
		}
	},
	finalize() {
		// JavaScript to be fired on all pages, after page specific JS is fired
		console.log('finalize common');
	},
};
